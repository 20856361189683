@font-face {
    font-family: 'Causten';
    src: local('Causten'),
    url(Causten-Regular.ttf) format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Causten';
    src: local('Causten'),
    url(Causten-Bold.ttf) format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'Causten';
    src: local('Causten'),
    url(Causten-Semi-Bold.ttf) format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Causten';
    src: local('Causten'),
    url(Causten-Medium.ttf) format('truetype');
    font-weight: 500;
}
